import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Profession } from 'src/app/interface/profession';
import { LayoutService } from 'src/app/service/layout.service';
import { ProfessionService } from 'src/app/service/profession.service';
import { SnackbarService } from 'src/app/service/snackbar.service';

@Component({
  selector: 'app-profession-edit-create',
  templateUrl: './profession-edit-create.component.html',
  styleUrl: './profession-edit-create.component.scss'
})
export class ProfessionEditCreateComponent implements OnInit {
  profession: Profession;
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { profession: Profession },
    private dialogRef: MatDialogRef<ProfessionEditCreateComponent>,
    private _professionService: ProfessionService,
    private _layout: LayoutService,
    private _snackbar: SnackbarService,
    private _formBuilder: FormBuilder,
    
  ) { 
    if(this.data){
      this.profession = data.profession ;
    }
  }

  ngOnInit() {
    this.buildForm()
  }

  buildForm() {
    if (this.profession) {
      this.form = this._formBuilder.group({
        _id: this.profession._id,
        description: [this.profession.description, Validators.required]
      })
    } else {
      this.form = this._formBuilder.group({
        id: "",
        description: ["", Validators.required],
      })
    }
  }

  async onSubmit(){
    const newProfession: Profession = this.form.getRawValue()
    const isUpdate = Boolean(newProfession._id);
    try {
      this._layout.loader = true
      if (isUpdate) {
        await this._professionService.update(newProfession)
        this._snackbar.success('PROFESSION.UPDATED');
      } else {
        const id = await this._professionService.register(newProfession);
        newProfession._id = id;
        this._snackbar.success('PROFESSION.ADDED');
      }
      this.dialogRef.close(newProfession);
    } catch (error) {
      console.error(error)
      this._snackbar.error(isUpdate ? 'PROFESSION.NOT_UPDATED' : 'PROFESSION.NOT_ADDED');
    } finally {
      this._layout.loader = false;
    }
  }

  close() {
    this.dialogRef.close(null);
  }
}
